import axios from "axios";
import Vue from "vue";
// 请求超时时间
axios.defaults.timeout = 10000 * 5;

// 请求基础URL
let baseURL = null;
// baseURL = "https://test-jyrczp-admin.iguanwei.com";//测试域名
// baseURL = "http://121.229.56.251:8082";
baseURL = "https://zkzp.jyrcfzjt.com:8082"
Vue.prototype.$baseURL = baseURL;
localStorage.setItem("baseURL", baseURL);
axios.defaults.baseURL = baseURL + "/api";
// post请求头
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 请求携带cookie
// axios.defaults.withCredentials = true;

// 请求拦截器
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  config.headers.Authorization = `Bearer ${token}`
  if (token) {
    if (config && config.headers) {
      config.headers.token = `${token}`
    }
  }
  return config
},
  (error) => {
    return Promise.reject(error)
  }
)
// 响应拦截器
axios.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return Promise.resolve(response);
    }
  },
);
/**
* get方法，对应get请求
*/
export function get(url, params, info = '') {
  return new Promise((resolve, reject) => {
    axios.get(url + info, {
      params: params
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}
/**
 * post方法，对应post请求
 * info为 true，formData格式；
 * info为 undefined或false，是json格式
 */
export function post(url, data = {}, info) {
  return new Promise((resolve, reject) => {
    let newData = data
    if (info) {  //  转formData格式
      newData = new FormData();
      for (let i in data) {
        newData.append(i, data[i]);
      }
    }
    axios.post(url, newData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}

/**
 * 封装put请求
 */

export function put(url, params = {}, info = "") {
  return new Promise((resolve, reject) => {
    axios.put(url + info, params)
      .then(res => {
        resolve(res.data);
      }, err => {
        reject(err.data)
      })
  })
}

/**
* 封装delete请求
*/
export function axiosDelete(url, params = {}, info = "") {
  return new Promise((resolve, reject) => {
    axios.delete(url + info, {
      params: params
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data)
      })
  });
}

export function url(uri) {

  if (uri.charAt(0) !== "/") {
    uri = '/' + uri;
  }
  return axios.defaults.baseURL + uri;
}
