import { get, post } from '@/utils/request'
export const login = p => post('/user/login', p);
export const register = p => post('/user/register', p);
export const getZhaoping = p => get('/zhaopin/index', p);
export const getZpdetail = p => get('/zhaopin/detail', p);
export const getCarousel = p => get('/carousel/index', p);
export const getNotice = p => get('/notice/index', p);
export const getNoticedetail = p => get('/notice/detail', p);
export const getUndertaking = p => get('/company/undertaking', p);
export const getSwiper = p => get('/carousel/index', p);
export const applyinfo = p => get('/config/applyinfo', p);
export const zhiwei = p => get('/company/position', p);
export const companyDetail = p => get('/company/detail', p);
export const apply = p => post('/zhaopin/apply', p, true);
export const uploadapplyimg = p => post('/zhaopin/uploadapplyimg', p);
export const detail = p => get('/applyinfo/detail', p);
export const indexconfig = p => get('/config/indexconfig', p);
export const checkuserinfo = p => get('/user/checkuserinfo', p);
export const resetpwd = p => post('/user/resetpwd', p);
export const send = p => post('/sms/send', p, true);
export const gwlogin = p => get('/user/gwlogin', p);
