import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
export default {
  install(Vue, options) {
    Vue.prototype.getPdf = function (title) {
      // 特别重要：当页面超过一页，出现滚动条，滚动的部分生成的图片为空白
      // window.pageYoffset = 0
      // document.documentElement.scrollTop = 0
      // document.body.scrollTop = 0
      // 避免出现浏览器滚动条导致的内容不全处理
      document.body.scrollTop = 0;
      //div内部滚动导致内容不全处理
      document.getElementById("printTest1").style.height = 'auto';
      html2Canvas(document.querySelector('#printTest1'), {
        allowTaint: true,
        taintTest: false,
        useCORS: true,
      }).then(function (canvas) {
        // 获取canvas画布的宽高
        let contentWidth = canvas.width
        let contentHeight = canvas.height
        // 一页pdf显示html页面生成的canvas高度;
        let pageHeight = contentWidth / 592.28 * 841.89
        // 未生成pdf的html页面高度
        let leftHeight = contentHeight
        // 页面偏移
        let position = 0
        // html页面生成的canvas在pdf中图片的宽高（本例为：纵向a4纸[592.28,841.89]）
        let imgWidth = 595.28
        let imgHeight = 592.28 / contentWidth * contentHeight
        let pageData = canvas.toDataURL('image/jpeg', 1.0)
        let PDF = new JsPDF('', 'pt', 'a4')
        if (leftHeight < pageHeight) {
          // PDF.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight)
          PDF.addImage(pageData, 'JPEG', 97.71, position, imgWidth / 1.5, imgHeight / 1.5)
        } else {
          while (leftHeight > 0) {
            // PDF.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
            PDF.addImage(pageData, 'JPEG', 97.71, position, imgWidth / 1.5, imgHeight / 1.5)
            leftHeight -= pageHeight
            position -= 841.89
            // 避免添加空白页
            if (leftHeight > 0) {
              PDF.addPage()
            }
          }
        }
        PDF.save(title + '.pdf')
      }
      )
    }
  }
}
