import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',
    vuex_userinfo: {} // 存储用户数据对象
  },
  getters: {
  },
  mutations: {
    changeState (state, data) {
      state.token = data
    }
  },
  actions: {
  },
  modules: {
  }
})
